import React from "react"
import Layout from "../components/layout";
import {Typography, Grid, Container} from "@material-ui/core"
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles';

const useContainerStyles = makeStyles({
  root: {
    padding: '0 3rem',
    minHeight: '80vh',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  }
});

const StyledBar = styled('div')`
    width: 100%;
    height: 3px;
    z-index: 100;
    background-color: #eb996a;
    margin-bottom: 3rem;
`;

const useTextStyles = makeStyles({
  root: {
    textAlign: 'center',
    color: '#fff'
  }
});

const NotFoundPage = (props) => {
  const containerClasses = useContainerStyles();
  const textClasses = useTextStyles();

  return (
      <Layout>
        <Container className={containerClasses.root} maxWidth={'xl'}>
          <Grid container item spacing={4} justify={'center'}>
            <Grid item container justify={'center'}>
              <Typography variant={'h3'} color={'secondary'} style={{textAlign: 'center'}}>
                404
              </Typography>
            </Grid>
            <Grid item md={8}>
              <Typography variant={'h5'} className={textClasses.root}>
                Resource you are searching for isn't here.
              </Typography>
            </Grid>
            <Grid item container justify={'center'}>
              <Grid item md={2} xs={10}>
                <StyledBar/>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Layout>
  );
};

export default NotFoundPage
